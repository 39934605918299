@import '../../../scss/index.scss';

.status-order {
    height: calc(100vh - 84px);
    background-color: $background-light;
    overflow-y: scroll;
    &__container {
        padding: 0px 40px 0px 40px;
        .ant-descriptions-item {
            padding-bottom: 2px;
        }
        .ant-descriptions-item-label {
            font-weight: 600;
        }
        &-steps {
            padding: 20px;
        }
        &-buttons {
            margin-bottom: 20px;
            padding: 0px 40px 0px 40px;

            .btn-resfresh,
            .btn-delete {
                margin-top: 20px;
                margin-bottom: 10px;
            }
        }
    }
    span.anticon.anticon-caret-right.ant-collapse-arrow {
        top: 5px;
        left: 0px;
    }
    .ant-collapse-header {
        padding: 0px 16px !important;
        padding-left: 0px;
        font-weight: 600;
    }
    .ant-collapse-content-box {
        padding-top: 5px;
        padding-bottom: 5px;
    }
    &-items {
        margin: 0px;
    }
}
