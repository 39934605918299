.update-order {
    .active-scroll-modal {
        height: calc(100vh - 200px);
    }
    .ant-tabs-content-holder {
        height: calc(100vh - 200px);
        max-height: calc(100vh - 200px);
        overflow-y: scroll;
    }
}
