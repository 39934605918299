@import '../../../../scss/index.scss';
.list-users {
    &__header {
        // display: flex;
        // justify-content: space-between;
        margin-bottom: 20px;

        &-search {
            .ant-input-search-with-button {
                width: 300px;
            }
            text-align: left;
        }

        &-add {
            text-align: right;
            > button {
                margin-right: 20px;
            }
        }
    }

    .users-active {
        background-color: #fff;
        padding: 10px 20px;

        .ant-list-item-meta {
            align-items: center;
        }
    }

    /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (max-width: $media-breackpoint-up-sm) {
        .users-active {
            padding: 10px 0px;

            .ant-list-item-action {
                margin-bottom: 30px;
            }
        }

        .list-users__header-search {
            .ant-input-search-with-button {
                width: 100%;
            }
        }

        .list-users__header-add {
            text-align: left;
            > button {
                margin-top: 15px;
                width: 100%;
            }
        }
    }
}
